.carousel .thumb {
    border-color: #333333;
}

.carousel .thumb.selected {
    border-color: #FFFFFF;
}

.carousel .slide .legend {
    margin-top: -35%;
    bottom: unset;
    opacity: unset;
    background-color: unset;
}

@media (max-width: 1024px) {
    .carousel .slide .legend {
        margin-top: -55%;
    }
}

.carousel:hover .slide .legend {
    opacity: unset;
}

.custom-wrap-filter-box {
    /*width: 25%;*/
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    display: table-cell;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;
}

.custom-wrap-filter-box-end {
    /*width: 25%;*/
    /*border-right: 1px solid #e0e0e0;*/
    border-bottom: 1px solid #e0e0e0;
    display: table-cell;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;
}

.equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
}

.aligned-row {
    display: flex;
    flex-flow: row wrap;

    &::before {
        display: block;
    }
}

.custom-connect-btn {
    background-color: #333333;
    border-color: unset;
    border-radius: unset;
    width: 100%;
}

.custom-connect-btn:hover {
    background-color: #333333;
    border-color: unset;
    border-radius: unset;
}

.custom-connect-btn:active {
    background-color: #333333;
    border-color: unset;
    border-radius: unset;
}


.custom-connect-btn:focus {
    background-color: #333333;
    border-color: unset;
    border-radius: unset;
}


.custom-connect-btn:active:focus {
    background-color: #333333;
    border-color: unset;
    border-radius: unset;
}

.navbar-toggle,
.navbar-toggle:focus,
.navbar-toggle:active,
.navbar-toggle-icon:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}

.fill img {
    flex-shrink: 0;
    /*min-width: 100%;*/
    /*min-height: 100%*/
}

.custom-navlink {
    display: unset;
    position: unset;
}