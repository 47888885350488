.sticky-header {
    border-bottom: 1px solid #efefef;
    line-height: 20px;
    padding: 12px;
}

.sticky-header .u-line {
    color: #eb5050;
    text-decoration: underline;
}

.sticky-header .text {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    color: #aaaaaa;
    letter-spacing: 0.2em;
}

.sticky-header .text.text-w {
    color: #fff;
}

.count {
    width: 17px;
    height: 17px;
    line-height: 17px;
    display: inline-block;
    text-align: center;
    background: #eb5050;
    border-radius: 50%;
    color: #fff;
    font-size: 9px;
    position: relative;
    top: -10px;
}

@media (max-width: 1024px) {
    .main-content {
        margin-bottom: 30px;
    }
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase, .initialism {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.empty-cart-content h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 35px;
}

.space-padding-tb-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.close-empty-cart {
    display: block;
    border: 2px solid #000000;
    border-radius: 50%;
    cursor: pointer;
    height: 44px;
    position: relative;
    width: 44px;
    margin: 0 auto 26px;
}

.close-empty-cart:before {
    border-bottom: 2px solid #000000;
    content: "";
    left: 7px;
    position: absolute;
    top: 19px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    width: 26px;
}

.close-empty-cart:after {
    border-bottom: 2px solid #000000;
    content: "";
    left: 7px;
    position: absolute;
    top: 19px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    width: 26px;
}

.bordersolid-2 {
    border: 2px solid #000;
}

.bordersolid-2:hover {
    border: 2px solid #eb5050;
}

.btn-return {
    width: 187px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    color: #000;
    background: transparent;
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding-right: 10px;
}

.icon-arr {
    border-bottom: 1px solid;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    width: 18px;
}

.icon-arr:before {
    border-bottom: 1px solid;
    content: "";
    position: absolute;
    right: 0;
    top: -3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 7px;
}

.icon-arr:after {
    border-bottom: 1px solid;
    bottom: -4px;
    content: "";
    position: absolute;
    right: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 7px;
}

/*
	5.1. Cart Page
 */
table.table.cart-table tr:first-child td {
    border-top: 1px solid #eeeeee;
}

table.table.cart-table tr:last-child td {
    border-bottom: 1px solid #eeeeee;
}

table.table.cart-table th {
    border: none;
    padding: 0px;
    padding-bottom: 5px;
    padding-top: 35px;
}

table.table.cart-table td {
    padding: 25px 0px;
}

table.table.cart-table td:first-child {
    width: 110px;
}

table.table.cart-table td:nth-child(2) a {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

table.table.cart-table td:nth-child(2) .quantity {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 500;
}

table.table.cart-table td:nth-child(2) .quantity .product_quantity_number {
    width: 29px;
    height: 29px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ebebeb;
}

table.table.cart-table td:nth-child(2) .quantity .quantity-left-minus {
    width: 29px;
    height: 29px;
    border: 1px solid #ebebeb;
    text-align: center;
    margin-right: -1px;
    border-radius: 0;
    background: #fff;
    padding: 0;
}

table.table.cart-table td:nth-child(2) .quantity .quantity-left-minus:focus {
    outline: none;
}

table.table.cart-table td:nth-child(2) .quantity .quantity-left-minus:active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

table.table.cart-table td:nth-child(2) .quantity .quantity-right-plus {
    width: 29px;
    height: 29px;
    border: 1px solid #ebebeb;
    text-align: center;
    margin-left: -1px;
    border-radius: 0;
    background: #fff;
    padding: 0;
}

table.table.cart-table td:nth-child(2) .quantity .quantity-right-plus:focus {
    outline: none;
    background: none;
}

table.table.cart-table td:nth-child(2) .quantity .quantity-right-plus:active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

table.table.cart-table td:nth-child(3) {
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-align: right;
}

table.table.cart-table td:nth-child(3) a.remove {
    color: #000000;
}

table.table.cart-table td:nth-child(3) p {
    margin-top: 25px;
}

.link-button {
    width: 100%;
    border: 1px solid #dfdfdf;
    padding: 0;
    border-radius: 0;
    height: 52px;
    line-height: 52px;
}

.btn {
    font-size: 14px;
    font-weight: 500;
}

.link-button-v2 {
    font-size: 16px;
    font-weight: 500;
}

.hover-black:hover {
    background: #000;
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.hover-white {
    background: #000;
    color: #fff;
}

.hover-white:hover {
    background: #fff;
    color: #000;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.input-cart {
    height: 52px;
    border-radius: 0;
}

.input-cart::-webkit-input-placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

.input-cart:-moz-placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

.input-cart::-moz-placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

.input-cart:-ms-input-placeholder {
    color: #aaaaaa;
    font-size: 14px;
}

.coupon {
    background: black;
    padding: 30px 20px;
}

.coupon button {
    margin-top: 20px;
    /*border: none;*/
    -webkit-box-shadow: none;
    box-shadow: none;
}

.text-price {
    background: black;
    padding: 5px 20px;
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
}

.text-price ul li {
    display: block;
    width: 100%;
    float: left;
    padding-top: 20px;
}

.text-price ul li:first-child {
    padding: 20px 0px;
    border-bottom: 1px solid #dadada;
}

.text-price ul li:last-child {
    padding: 30px 0px;
    border-top: 1px solid #dadada;
}

.text-price ul li .payment {
    display: inline-block;
    margin-top: 20px;
    float: none;
}

@media (max-width: 1024px) {
    .text-price ul li .payment {
        float: right;
    }
}

.text-price ul li .payment label {
    width: 100%;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #aaaaaa;
    cursor: pointer;
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;
}

.text-price ul li .payment label:before {
    content: "\f10c";
    display: inline-block;
    width: 9px;
    height: 9px;
    font-family: 'FontAwesome';
    font-size: 12px;
    margin-right: 10px;
    color: #aaaaaa;
    position: absolute;
    left: 0;
    bottom: 50%;
}

.text-price ul li .payment label span {
    font-size: 14px;
    font-weight: normal;
}

.text-price ul li .payment input[type=radio]:checked + label:before {
    content: "\f111";
    color: #000;
    font-size: 12px;
}

.text-price ul li .payment input {
    display: none;
}

.text-price ul li span {
    font-size: 16px;
    font-weight: 500;
}

.text-price ul li .text {
    float: left;
}

.text-price ul li .number {
    float: right;
}

.text-price ul li .calculate {
    display: inline-block;
    padding-bottom: 8px;
    margin-bottom: 25px;
    position: relative;
    width: 100%;
    cursor: pointer;
}

.text-price ul li .calculate:after {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 12px;
    width: 12px;
    font-family: "FontAwesome";
    content: "\f0d7";
    color: #000;
}

.cart-box-container-ver2 h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
}

.zipcode {
    display: none;
    margin-bottom: 30px;
}

.bordersolid-2 {
    border: 2px solid #000;
}

.bordersolid-2:hover {
    border: 2px solid #eb5050;
}

.btn-return {
    width: 187px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    color: #000;
    background: transparent;
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding-right: 10px;
}

.icon-arr {
    border-bottom: 1px solid;
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    width: 18px;
}

.icon-arr:before {
    border-bottom: 1px solid;
    content: "";
    position: absolute;
    right: 0;
    top: -3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 7px;
}

.icon-arr:after {
    border-bottom: 1px solid;
    bottom: -4px;
    content: "";
    position: absolute;
    right: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 7px;
}

.close-empty-cart {
    display: block;
    border: 2px solid #000000;
    border-radius: 50%;
    cursor: pointer;
    height: 44px;
    position: relative;
    width: 44px;
    margin: 0 auto 26px;
}

.close-empty-cart:before {
    border-bottom: 2px solid #000000;
    content: "";
    left: 7px;
    position: absolute;
    top: 19px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    width: 26px;
}

.close-empty-cart:after {
    border-bottom: 2px solid #000000;
    content: "";
    left: 7px;
    position: absolute;
    top: 19px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    width: 26px;
}

.empty-cart-content h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 35px;
}

.cart-box-container-ver2 {
    padding-top: 95px;
    padding-bottom: 132px;
}

@media (min-width: 767px) {
    .cart-box-container-ver2 {
        padding: 50px 0;
    }
}

/*
	5.3. Checkout Page
 */
.checkout-login .form-login {
    display: none;
}

.checkout-login .form-login p {
    color: #a4a4a4;
    line-height: 24px;
    margin-bottom: 20px;
}

.form-cp {
    display: none;
}

.box-toggle {
    font-weight: 500;
    color: #a4a4a4;
    display: inline-block;
    text-align: center;
    width: 100%;
    border: 1px solid #f1f1f1;
    height: 82px;
    line-height: 82px;
}

.box-toggle a {
    text-decoration: underline;
}

.box-toggle {
    margin-bottom: 35px;
}

@media (min-width: 480px) {
    .checkout-cp[class*="col-sm-"] {
        padding: 0;
    }
}

.checkout-cp input {
    background: #f8f8f8;
}

.form-cp > * {
    display: inline-block;
}

@media (min-width: 480px) {
    .form-cp > * input {
        width: 413px;
    }
}

.btn-cp-inline {
    width: 128px;
    height: 54px;
    line-height: 54px;
    padding: 0;
    border-radius: 0;
    float: right;
}

.checkout .cart-list .product-price {
    float: none;
}

.checkout .cart-list .quantity {
    float: none;
    font-size: 14px;
    font-weight: 500;
}

.checkout .cart-list .list > li .text .product-name {
    margin-bottom: 5px;
}

.box-payment ul li:first-child {
    border: 0;
}

.box-payment ul li .payment label {
    color: #000;
}

.box-payment ul li .payment .no-checkbox {
    color: #a4a4a4;
    line-height: 18px;
    font-size: 12px;
    padding-left: 20px;
    margin-bottom: 35px;
}

.btn-checkout {
    height: 83px;
    line-height: 83px;
    font-size: 18px;
}

.form-note {
    background: #f8f8f8;
    border: 1px solid #f1f1f1;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}


.banner-heading img {
    width: 100%;
}

.page-heading {
    position: relative;
}

.banner-heading {
    position: relative;
}

.banner-heading.hero-dark {
    min-height: 262px;
    background: #39393d;
}

.banner-heading.hero-light {
    min-height: 262px;
    background: #ebebeb;
}

.heading-content {
    position: absolute;
    top: 40%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    z-index: 8;
}

@media (max-width: 480px) {
    .heading-content {
        top: 50%;
    }
}

@media (min-width: 768px) {
    .heading-content.v2 {
        top: 35%;
    }
}

@media (min-width: 768px) {
    .heading-content.v3 {
        top: 58%;
    }
}

.heading-content .page-title {
    font-weight: 500;
}

@media (max-width: 480px) {
    .heading-content .page-title {
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .heading-content .page-title {
        margin: 15px 0;
    }
}

@media (min-width: 1025px) {
    .heading-content .page-title {
        font-size: 60px;
        margin-bottom: 40px;
    }

    .heading-content .page-title.v2 {
        font-size: 30px;
    }
}

.heading-content .page-title:before {
    border-bottom: 2px solid #eb5050;
    top: 50%;
    content: "";
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 50px;
    height: 2px;
}

@media (max-width: 480px) {
    .heading-content .page-title:before {
        top: 30%;
    }
}

.heading-content .page-title.white {
    color: #fff;
}

.heading-content .page-title.v2:before {
    content: none;
}

.heading-content .page-title.v2 {
    float: left;
    margin-bottom: 0;
}

.heading-content .breadcrumb {
    background: transparent;
}

.heading-content .breadcrumb li a {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: normal;
}

.heading-content .breadcrumb li + li:before {
    content: "\F105";
    font-family: 'FontAwesome', sans-serif;
    color: #000;
}

.heading-content .breadcrumb.black a {
    color: #000;
}

.heading-content .breadcrumb.white a {
    color: #fff;
}

.heading-content .breadcrumb.white li + li:before {
    color: #fff;
}

.heading-content .breadcrumb.v2 {
    float: right;
    margin: 12px 0 0 0;
}

.heading-content .breadcrumb.v2 > li > a.active {
    color: #a4a4a4;
}

.hero-light .breadcrumb > li > a {
    color: #000;
}

.wrap-filter {
    display: table;
    width: 100%;
    position: relative;
}

.wrap-filter .wrap-filter-box {
    width: 25%;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    display: table-cell;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;
}

.wrap-filter .wrap-filter-box.filter-half {
    width: 50%;
    border-right: 0;
    border-left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
}

@media (max-width: 768px) {
    .wrap-filter .wrap-filter-box.filter-half {
        line-height: 40px;
    }
}

@media (max-width: 767px) {
    .wrap-filter .wrap-filter-box {
        display: inline-block;
        width: 33.333333%;
        float: left;
        padding: 10px;
        line-height: 40px;
        border-top: 1px solid #e0e0e0;
    }

    .wrap-filter .wrap-filter-box.filter-half {
        border: 0;
        width: 100%;
        border-right: 0;
        border-left: 0;
        line-height: unset;
        padding: 10px 15px;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .wrap-filter .wrap-filter-box.wrap-filter-sorting {
        width: 100%;
        border-top: 0;
    }

    .wrap-filter .wrap-filter-box:nth-child(3) {
        line-height: 20px;
    }
}

@media (min-width: 1025px) {
    .wrap-filter .wrap-filter-box {
        width: 20%;
    }

    .wrap-filter .wrap-filter-box.filter-half {
        width: 50%;
    }
}

.wrap-filter-tab {
    padding-left: 15px;
}

.wrap-filter-number.right {
    text-align: right;
    padding-right: 15px;
}

@media (min-width: 1025px) {
    .wrap-filter-number {
        text-align: left;
        padding-left: 42px;
        display: block;
    }

    .wrap-filter-number.right {
        text-align: right;
        padding-right: 42px;
    }

    .wrap-filter-tab {
        padding-left: 42px;
    }

    .wrap-filter-sorting {
        text-align: right;
        padding-right: 45px;
    }
}

.wrap-filter-box.wrap-filter-sorting {
    position: relative;
    border-right: 0;
}

.wrap-filter-box button {
    position: relative;
    font-size: 12px;
    color: #868686;
    border: 0;
    background: transparent;
    padding: 0;
    margin-right: 20px;
}

.wrap-filter-box button:before {
    color: #000000;
    content: "\f0d7";
    font-family: 'FontAwesome', sans-serif;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: -20px;
    display: block;
    height: 100%;
}

.filter-title:after {
    display: block;
    content: "Filters";
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    line-height: 2;
}

@media (min-width: 1200px) {
    [class*="col-md-"].collection-list {
        width: 80%;
    }

    [class*="col-md-"].collection-sidebar {
        width: 20%;
    }
}

.filter-product {
    padding-top: 34px;
}

.filter-product .filtertitle {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
}

.filter-product .filter-content {
    padding-top: 30px;
    border-top: 1px solid #e7e7e7;
    padding-bottom: 60px;
}

.filter-product .filter-content > li {
    position: relative;
}

.filter-product .filter-content > li > a {
    font: 500 14px/35px "Poppins", sans-serif;
    color: #a4a4a4;
    display: block;
    position: relative;
    text-transform: capitalize;
}

.filter-product .filter-content > li > a:hover {
    color: #eb5050;
}

.filter-product .filter-content > li .filter-menu {
    margin-left: 10px;
    display: none;
}

.filter-product .filter-content > li .filter-menu > li > a {
    font-size: 12px;
    color: #a4a4a4;
}

.filter-product .filter-content > li .filter-menu > li > a:hover {
    color: #eb5050;
}

.filter-product .filter-content .plus,
.filter-product .filter-content .minus {
    position: absolute;
    right: 0;
    top: 0;
    width: 23px;
    height: 23px;
}

.filter-product .filter-content .plus:after {
    content: "\f489";
    display: block;
    font-family: "ionicons";
    font-size: 20px;
    position: absolute;
    right: 16px;
    top: 5px;
    color: #333;
    cursor: pointer;
    -moz-transition: all 0.1s ease 0s;
    -webkit-transition: all 0.1s ease 0s;
    -o-transition: all 0.1s ease 0s;
}

.filter-product .filter-content .minus:after {
    content: "\f462";
    display: block;
    font-family: "ionicons";
    font-size: 20px;
    position: absolute;
    right: 16px;
    top: 5px;
    color: #333;
    cursor: pointer;
    -moz-transition: all 0.1s ease 0s;
    -webkit-transition: all 0.1s ease 0s;
    -o-transition: all 0.1s ease 0s;
}

.filter-product .filter-color .filter-content > li > a {
    font-weight: 600;
    text-transform: capitalize;
}

.filter-product .filter-size .filter-content .btn {
    width: 100%;
    border-radius: 0;
    background: transparent;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 3px solid #000;
    color: #a4a4a4;
    font-weight: normal;
}

.filter-product .filter-size .filter-content .btn .caret {
    float: right;
    margin: 6px 0;
    color: #000;
}

.filter-product .filter-size .filter-content .btn:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.filter-product .filter-size .filter-content .dropdown-menu {
    width: 100%;
}

.filter-product .filter-size .filter-content .dropdown-menu > li > a {
    text-transform: uppercase;
}

.filter-product .filter-price .filter-title {
    float: right;
    text-align: right;
    border: 2px solid #000;
    width: 75px;
    height: 28px;
    line-height: 28px;
}

.filter-product .filter-price .filter-title:after {
    line-height: unset;
}

.filter-product .filter-price .min-max {
    font-size: 12px;
    color: #868686;
}

@media (max-width: 480px) {
    /*
            7.4. Mobile

                7.4.2. Max mobile
     */
    .pushmenu-push-toright-cart {
        right: 320px;
    }

    .pushmenu.pushmenu-left {
        width: 320px;
    }

    .menu-social {
        width: 320px;
    }

    .cart-bottom {
        width: 300px;
    }

    .wrap-gallery-grid [class*="col-xs-"] {
        width: 100%;
    }

    .btn-shoplook {
        font-size: 10px;
    }

    .product-grid [class*="col-xs-"] {
        width: 100%;
    }

    .wrapper-product [class*="col-xs-"] {
        width: 100%;
    }

    .wrapper-category [class*="col-xs-"] {
        width: 100%;
    }

    .slider-homesimple .slider-content {
        min-width: 280px;
    }
}


.overlay-img {
    background-color: rgba(0, 0, 0, 0.48);
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.overlay-img.ver2 {
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(13%, rgba(255, 255, 255, 0.09)), color-stop(26%, rgba(255, 255, 255, 0.21)), color-stop(61%, rgba(255, 255, 255, 0.65)), color-stop(80%, rgba(255, 255, 255, 0.87)), color-stop(93%, rgba(255, 255, 255, 0.98)), to(white));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.09) 13%, rgba(255, 255, 255, 0.21) 26%, rgba(255, 255, 255, 0.65) 61%, rgba(255, 255, 255, 0.87) 80%, rgba(255, 255, 255, 0.98) 93%, white 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.overlay-img.ver3 {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.91+0,0.91+100 */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.91)), to(rgba(255, 255, 255, 0.91)));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.91) 0%, rgba(255, 255, 255, 0.91) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.overlay-img.ver4 {
    background-color: rgba(0, 0, 0, 0.6);
}

@media (max-width: 1024px) {
    .overlay-img {
        opacity: 1;
    }
}


.product-img {
    position: relative;
}

.product-info {
    color: #fff;
    font-weight: 500;
    text-align: center;
}

@media (min-width: 1025px) {
    .product-info {
        opacity: 0;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
    }
}

.product-info .product-price-ol {
    font-size: 14px;
}

.product-info .product-title {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.product-info .product-title a {
    color: #fff;
}

.product-info .product-title:before {
    border-bottom: 1px solid #ff0000;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
    position: absolute;
    width: 67px;
}

.product-info .btn-quickview {
    color: #fff;
}

@media (min-width: 1025px) {
    .product-img:hover .product-info {
        opacity: 1;
    }
}

.product-grid {
    padding-top: 34px;
}

.product-grid.modern-grid {
    padding-top: 40px;
}

.product-grid.modern-grid-custom {
    padding-top: 40px;
}

.product-grid.modern-grid-custom.space-v1 {
    padding-bottom: 40px;
}

@media (min-width: 768px) {
    .product-grid.product-custom-bg {
        background: #f1f1f1;
    }

    .product-grid.product-custom-bg [class*="col-"] {
        padding: 0;
    }

    .product-grid.product-custom-bg .row {
        margin: 0;
    }
}

@media (min-width: 768px) {
    .product-grid.product-flat [class*="col-"] {
        padding: 0;
        border-bottom: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
    }

    .product-grid.product-flat .row {
        margin: 0;
    }
}

.product-grid.product-custom-bg.product-list {
    background: transparent;
}

.box-center {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.box-center.v2 {
    height: 85%;
}

@media (min-width: 1025px) {
    .box-center.v3 {
        height: 90%;
    }
}

.box-center.right {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
}

.box-center.left {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
}

.box-center.ver4 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: unset;
    flex-direction: unset;
}


.single-product-detail .product-images {
    position: relative;
}

.single-product-detail .product-images img {
    width: unset;
}

.single-product-detail .single-product-info .rating-star {
    margin-bottom: 20px;
}

.single-product-detail .single-product-info .rating-star .icon-rating {
    float: left;
    margin-right: 10px;
}

.single-product-detail .single-product-info .rating-star .review {
    font-size: 12px;
    font-weight: 500;
    color: #eb5050;
}

.single-product-detail .single-product-info .action .quantity {
    float: left;
    margin-right: 18px;
}

@media (max-width: 767px) {
    .single-product-detail .single-product-info .action .quantity {
        float: none;
    }
}

.single-product-detail .single-product-info .action .quantity .product_quantity_number {
    width: 54px;
    height: 54px;
    line-height: 53px;
    text-align: center;
    border: 1px solid #000;
    font-size: 22px;
    font-weight: 500;
    vertical-align: bottom;
}

.single-product-detail .single-product-info .action .quantity .quantity-left-minus {
    width: 54px;
    height: 54px;
    border: 1px solid #000;
    text-align: center;
    margin-right: -6px;
    border-radius: 0;
    background: #fff;
    padding: 0;
    font-size: 22px;
}

.single-product-detail .single-product-info .action .quantity .quantity-left-minus:focus {
    outline: none;
}

.single-product-detail .single-product-info .action .quantity .quantity-left-minus:active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.single-product-detail .single-product-info .action .quantity .quantity-right-plus {
    width: 54px;
    height: 54px;
    border: 1px solid #000;
    text-align: center;
    margin-left: -6px;
    border-radius: 0;
    background: #fff;
    padding: 0;
    font-size: 22px;
}

.single-product-detail .single-product-info .action .quantity .quantity-right-plus:focus {
    outline: none;
}

.single-product-detail .single-product-info .action .quantity .quantity-right-plus:active {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.single-product-detail .single-product-info .action .add-cart, .single-product-detail .single-product-info .action .tab-desc .product-info-ver2 .btn-download, .tab-desc .product-info-ver2 .single-product-detail .single-product-info .action .btn-download {
    width: 237px;
    height: 54px;
    line-height: 54px;
    background: #000;
    color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-right: 13px;
}

.single-product-detail .single-product-info .action .add-cart:hover, .single-product-detail .single-product-info .action .tab-desc .product-info-ver2 .btn-download:hover, .tab-desc .product-info-ver2 .single-product-detail .single-product-info .action .btn-download:hover {
    background: #eb5050;
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.single-product-detail .single-product-info .action .wish {
    width: 54px;
    height: 54px;
    line-height: 54px;
    display: inline-block;
    text-align: center;
    border: 1px solid #000;
}

@media (max-width: 768px) {
    .single-product-detail .single-product-info .action .link-ver1 {
        margin-top: 15px;
    }

    .single-product-detail .single-product-info .action .link-ver1.v2 {
        margin-top: 0;
    }
}

.single-product-detail .single-product-info .product-title a {
    font-size: 30px;
}

.single-product-detail .single-product-info .product-price {
    margin: 20px 0 30px 0;
}

.single-product-detail .single-product-info .product-price span {
    font-weight: 500;
    font-size: 22px;
    color: #eb5050;
}

.single-product-detail .single-product-info .product-price span.old {
    font-size: 16px;
    text-decoration: line-through;
    color: #a4a4a4;
    margin-right: 5px;
}

.single-product-detail .single-product-info .product-desc {
    color: #a4a4a4;
    font: 14px/26px "Poppins", sans-serif;
    padding-top: 30px;
    padding-bottom: 45px;
    border-top: 1px solid #eeeeee;
}

.single-product-detail .single-product-info .product-desc.space-v1 {
    padding-bottom: 40px;
}

.single-product-detail .single-product-info .share-social {
    text-align: right;
}

@media (max-width: 768px) {
    .single-product-detail .single-product-info .share-social {
        text-align: unset;
    }
}

.single-product-detail .single-product-info .share-social span {
    font-weight: 500;
    text-decoration: underline;
}

.single-product-detail .single-product-info .share-social a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    background: #f3f3f3;
    margin: 0 5px;
}

.single-product-detail .single-product-info .share-social a:last-child {
    margin-right: 0;
}

@media (min-width: 1025px) {
    .single-product-detail.product-bundle .single-product-info {
        padding-left: 69px;
    }
}

.single-product-detail.product-bundle .form-group {
    position: relative;
}

.single-product-detail.product-bundle .form-group label {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 12px;
}

.single-product-detail.product-bundle .form-group .form-control {
    font-size: 14px;
    color: #a4a4a4;
    border-radius: 0;
    border: 1px solid #f1f1f1;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #f8f8f8;
    height: 54px;
    -webkit-appearance: none;
}

.single-product-detail.product-bundle .form-group:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
    position: absolute;
    right: 20px;
    bottom: 23px;
    pointer-events: none;
}

.single-product-detail.product-bundle .bundle .product-price span {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.single-product-detail.product-bundle .bundle .product-price span .old {
    font-size: 18px;
}

.single-product-detail.product-bundle .bundle p {
    font: 14px/26px "Poppins", sans-serif;
    color: #a4a4a4;
}

.single-product-detail.product-bundle .bundle .bundle-total {
    font-size: 18px;
    font-weight: 500;
    margin: 25px 0 35px 0;
}

.single-product-detail.product-bundle .bundle .bundle-total .product-price {
    display: inline;
}

.action {
    margin: 30px 0 30px 0;
}

.action.v2 {
    margin: 0 0 30px 0;
}

.action.v3 {
    margin: 20px 0 20px 0;
}

.action.v4 {
    margin: 40px 0 40px 0;
}

.action.v5 {
    margin: 30px 0 40px 0;
}

.action.v6 {
    margin: 40px 0 20px 0;
}

.action.v7 {
    margin: 0;
}

.product-bundle-list {
    padding-top: 10px;
}

.product-bundle-list .product-bundle-item {
    padding: 20px 0 20px 35px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    cursor: pointer;
}

.product-bundle-list .product-bundle-item:before {
    content: "\f192";
    display: inline-block;
    width: 12px;
    height: 12px;
    font-family: 'FontAwesome';
    font-size: 16px;
    margin-right: 10px;
    color: #b2b2b2;
    position: absolute;
    left: 0;
    bottom: 50%;
}

.product-bundle-list .product-bundle-item.active:before {
    content: "\f192";
    color: #000;
    font-size: 16px;
}

.product-bundle-list .product-bundle-item .product-images {
    display: table-cell;
    vertical-align: top;
    width: 20%;
}

.product-bundle-list .product-bundle-item .product-info-ver2 {
    display: table-cell;
    vertical-align: top;
    padding-left: 18px;
    width: 80%;
}

.product-bundle-list .product-bundle-item .product-info-ver2 .product-title {
    margin-top: 0;
}

.product-bundle-list .product-bundle-item .product-info-ver2 .product-title a {
    font-size: 14px;
}

.product-bundle-list .product-bundle-item .product-info-ver2 .product-price {
    margin: 10px 0 0 0;
}

.product-bundle-list .product-bundle-item .product-info-ver2 .product-price span {
    font-size: 14px;
}


.f-10 {
    font-size: 10px;
}

.f-15 {
    font-size: 15px;
}

.f-20 {
    font-size: 20px;
}

.bd-rl {
    border-right: 1px solid #4e4e4e;
    border-left: 1px solid #4e4e4e;
}

/*Padding Around the Block*/
@media (max-width: 812px) {
    .pd-sm-20 {
        padding: 20px 0;
    }

    .pd-sm-20::before, .pd-sm-20::after {
        content: " ";
        display: table;
    }

    .pd-sm-20:after {
        clear: both;
    }
}

.space-padding-0 {
    padding: 0;
}

.space-padding-10 {
    padding: 10px;
}

.space-padding-20 {
    padding: 20px;
}

.space-padding-30 {
    padding: 30px;
}

.space-padding-40 {
    padding: 40px;
}

.space-padding-50 {
    padding: 50px;
}

/*Padding Top & Bottom*/
.space-padding-tb-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.space-padding-tb-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.space-padding-tb-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.space-padding-tb-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.space-padding-tb-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.space-padding-tb-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.space-padding-tb-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.space-padding-tb-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.space-padding-tb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.space-padding-tb-130 {
    padding-top: 130px;
    padding-bottom: 130px;
}

.margin-0 {
    margin: 0px;
}

/*Margin Bottom*/
.space-5 {
    margin-bottom: 5px;
}

.space-10 {
    margin-bottom: 10px;
}

.space-15 {
    margin-bottom: 15px;
}

.space-20 {
    margin-bottom: 20px;
}

.space-25 {
    margin-bottom: 25px;
}

.space-30 {
    margin-bottom: 30px;
}

.space-35 {
    margin-bottom: 35px;
}

.space-40 {
    margin-bottom: 40px;
}

.space-45 {
    margin-bottom: 45px;
}

.space-50 {
    margin-bottom: 50px;
}

.space-55 {
    margin-bottom: 55px;
}

.space-60 {
    margin-bottom: 60px;
}

.space-65 {
    margin-bottom: 65px;
}

.space-70 {
    margin-bottom: 70px;
}

.space-75 {
    margin-bottom: 75px;
}

.space-80 {
    margin-bottom: 80px;
}

.space-85 {
    margin-bottom: 85px;
}

.space-90 {
    margin-bottom: 90px;
}

.space-95 {
    margin-bottom: 95px;
}

.space-100 {
    margin-bottom: 100px;
}

.space-32 {
    margin-bottom: 32px;
}

/*Margin top*/
.space-margin-top-0 {
    margin-top: 0px;
}

.space-margin-top-85 {
    margin-top: 85px;
}

.space-margin-top-50 {
    margin-top: 50px;
}

@media (max-width: 767px) {
    .space-margin-top-50 {
        margin-top: 0px;
    }
}

@media (min-width: 1025px) {
    .hover-images {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
    }

    .hover-images img {
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
    }

    .hover-images:hover img {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
}

.container-fullwidth {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
}

.single-product-tab .nav-tabs {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 54px;
    border: 0;
}

.single-product-tab .nav-tabs > li {
    float: none;
    display: inline-block;
    text-align: center;
}

.single-product-tab .nav-tabs > li > a {
    border: 0;
    font-size: 17px;
    font-weight: 500;
    color: #a4a4a4;
}

@media (min-width: 768px) {
    .single-product-tab .nav-tabs > li > a {
        padding-left: 0;
        padding-right: 0;
        margin-right: 40px;
    }
}

.single-product-tab .nav-tabs > li > a:hover {
    border: 0;
}

@media (max-width: 767px) {
    .single-product-tab .nav-tabs > li {
        display: block;
    }
}

.single-product-tab .nav-tabs li.active > a {
    background-color: transparent;
    border: 0;
    color: #000;
}

.single-product-tab .nav-tabs li.active > a:before {
    border-bottom: 1px solid #eb5050;
    bottom: 0;
    left: 0;
    content: "";
    position: absolute;
    width: 100%;
}

@media (max-width: 767px) {
    .single-product-tab .nav-tabs li.active > a:before {
        content: none;
    }
}

.single-product-tab .nav-tabs li.active:hover {
    border: 0;
}

.single-product-tab .nav-tabs.nav-space {
    margin-bottom: 67px;
}

.single-product-tab .tab-content p {
    font-size: 14px;
    line-height: 26px;
    color: #a4a4a4;
}

@media (max-width: 767px) {
    .single-product-tab .tab-content p {
        padding: 20px 0;
    }
}

.single-product-tab .tab-content .p-center {
    text-align: center;
}

@media (max-width: 812px) {
    .single-product-tab .tab-content .p-center {
        padding: 0 15px;
    }
}

@media (min-width: 1025px) {
    .single-product-tab .tab-content .p-center {
        width: 756px;
        margin: 0 auto;
    }
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}


.tab-group:first-child {
    background: #f8f8f8;
}

.tab-group:nth-child(2n+1) {
    background: #f8f8f8;
}

@media (min-width: 768px) {
    .tab-group .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.tab-group .product-info-ver2 .product-title {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.tab-group .product-info-ver2 .product-title a {
    font-size: 30px;
    color: #000;
}

.tab-group .product-info-ver2 .product-title:before {
    border-bottom: 1px solid #eb5050;
    bottom: 0;
    left: 0;
    content: "";
    position: absolute;
    width: 73px;
}

.tab-group .product-info-ver2 .product-desc {
    max-width: 379px;
    text-align: left;
    margin: unset;
}

.tab-simple > [class*="col-"] {
    padding: 0;
}

.tab-simple .product-images {
    position: relative;
}

@media (min-width: 1024px) {
    .tab-simple .tab-simple-content {
        display: inline-block;
        position: absolute;
        bottom: 30px;
        right: 42px;
        z-index: 1;
    }
}

@media (min-width: 1500px) {
    .tab-simple .tab-simple-content {
        width: 1669px;
        bottom: 96px;
    }
}

@media (min-width: 1024px) {
    .tab-simple .tab-simple-content .product-info-ver2 h3 a {
        color: #fff;
    }
}

.tab-simple .createdby {
    font-size: 80px;
    font-family: "Playfair Display";
    font-weight: 600;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (max-width: 1366px) {
    .tab-simple .createdby {
        font-size: 60px;
    }
}

@media (max-width: 1024px) {
    .tab-simple .createdby {
        font-size: 25px;
    }
}

@media (max-width: 480px) {
    .tab-simple .createdby {
        font-size: 14px;
    }
}

.tab-simple .createdby span {
    font-style: italic;
    font-weight: normal;
}

.tab-variable {
    display: inline-block;
    vertical-align: bottom;
}

.tab-variable > [class*="col-"] {
    padding: 0;
}

@media (min-width: 768px) {
    .tab-variable {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

.tab-variable:nth-child(2n+1) {
    background: #000000;
}

@media (min-width: 1500px) {
    .tab-variable-col {
        width: 100%;
        padding: 165px 175px 193px 175px;
    }
}

.tab-variable-col .product-info-ver2 {
    padding: 35px 0;
}

.tab-variable-col .product-info-ver2:first-child {
    border-bottom: 1px solid #eaeaea;
}

@media (max-width: 1024px) {
    .tab-variable-col .product-info-ver2 {
        margin: 0 auto;
        text-align: center;
    }

    .tab-variable-col .product-info-ver2 .product-title:before {
        margin: 0 auto;
        right: 0;
    }
}

.product-aff {
    margin-bottom: 100px;
}

.single-product-tab {
    margin-bottom: 75px;
}

.single-product-tab.ver2 {
    margin-bottom: 0px;
}

.single-product-tab .product-info-ver2.v2 .product-title {
    margin-bottom: 25px;
}

.single-product-left .product-images {
    margin-bottom: 30px;
}

@media (min-width: 1500px) {
    .single-product-info.v2 {
        padding-left: 69px;
    }
}

.single-product-right {
    max-width: 555px;
    position: static;
}

.tab-desc {
    display: inline-block;
    vertical-align: bottom;
}

.tab-desc [class*="col-"] {
    padding: 0;
}

@media (min-width: 1025px) {
    .tab-desc {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: #171717;
    }
}

.tab-desc .column-1 {
    background: #171717;
}

.tab-desc .product-info-ver2 .product-title {
    position: relative;
    max-width: 348px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding-bottom: 20px;
}

.tab-desc .product-info-ver2 .product-title a {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
}

@media (max-width: 767px) {
    .tab-desc .product-info-ver2 .product-title a {
        font-size: 20px;
    }
}

.tab-desc .product-info-ver2 .product-title:before {
    border-bottom: 1px solid #eb5050;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
    position: absolute;
    width: 73px;
}

.tab-desc .product-info-ver2 .product-desc {
    padding: 0 0 45px 0;
}

.tab-desc .product-info-ver2 .btn-download {
    background: #eb5050;
    margin-right: 0;
}

.img-reponsive {
    max-width: 100%;
}